const pools = [
    {
        id: "FIBER0001",
        name: "Piscina con solarium húmedo",
        description: "Este modelo en particular cuenta con generoso Solárium en forma de escalera donde pueden sentarse de 6 a 8 personas cómodas. El mismo mide 1.35 mts. de largo por 3.30 mts. de ancho y 60 cm de profundidad.",
        category: "Piscinas de fibra",
        sizes: ["10,00 x 3,60 x 1,50 mts.", "9,00 x 3,60 x 1,50 mts.", "8,00 x 3,60 x 1,50 mts.", "7,00 x 3,60 x 1,50 mts."],
        tags: ['piscina'],
        picture: "assets/pools/piscina_solarium_humedo_blue.webp",
        pictures: ["assets/pools/piscina_solarium_humedo_blue.webp", "assets/pools/piscina_solarium_humedo_white.webp", "assets/pools/piscina_solarium_humedo_green.webp"]
    },
    {
        id: "FIBER0002",
        name: "Piscina minimalista con playa húmeda",
        description: "La particularidad de este modelo es que cuenta con una Playa Húmeda incorporada, la misma forma parte de la escalera y mide 1.50 mts. de largo por 3.30 mts. de ancho con 20 cm de profundidad, proporcionando un espacio ideal para el descanso y disfrute en familia.",
        category: "Piscinas de fibra",
        sizes: ["8,00 x 3,60 x 1,50 mts.", "7,00 x 3,60 x 1,50 mts.", "6,00 x 3,60 x 1,50 mts."],
        tags: [],
        picture: "assets/pools/piscina_playa_humeda_blue.webp",
        pictures: "[assets/pools/piscina_playa_humeda_blue.webp, assets/pools/piscina_playa_humeda_white.webp, assets/pools/piscina_playa_humeda_green.webp]"
    },
    {
        id: "FIBER0003",
        name: "Piscina con desnivel escalera lateral",
        description: "Lo lindo de estos modelos es el desnivel , permitiendo que la familia, grandes y chicos puedan disfrutar la piscina en cada sector particular.",
        category: "Piscinas de fibra",
        sizes: ["6,50 x 3,00 x 1,35 a 1,65 mts."],
        tags: [],
        picture: "assets/pools/piscina_desnivel_lateral_blue.webp",
        pictures: ["assets/pools/piscina_desnivel_lateral_blue.webp", "assets/pools/piscina_desnivel_lateral_white.webp", "assets/pools/piscina_desnivel_lateral_green.webp"]
    },
    {
        id: "FIBER0004",
        name: "Piscina con desnivel romana",
        description: "Lo lindo de estos modelos es el desnivel , permitiendo que la familia, grandes y chicos puedan disfrutar la piscina en cada sector particular.",
        category: "Piscinas de fibra",
        sizes: ["8,45 x 3,40 x 1,55 a 1,92 mts."],
        tags: [],
        picture: "assets/pools/piscina_desnivel_romana_blue.webp",
        pictures: ["assets/pools/piscina_desnivel_romana_blue.webp", "assets/pools/piscina_desnivel_romana_white.webp", "assets/pools/piscina_desnivel_romana_green.webp"]
    },
    {
        id: "FIBER0005",
        name: "Piscina con arco romano",
        description: "",
        category: "Piscinas de fibra",
        sizes: ["6,50 x 3,10 x 1,45 mts.", "5,50 x 3,10 x 1,45 mts."],
        tags: [],
        picture: "assets/pools/piscina_arco_romano_blue.webp",
        pictures: ["assets/pools/piscina_arco_romano_blue.webp", "assets/pools/piscina_arco_romano_white.webp", "assets/pools/piscina_arco_romano_green.webp"]
    },
    {
        id: "FIBER0006",
        name: "Piscina con arco romano pequeña",
        description: "",
        category: "Piscinas de fibra",
        sizes: ["4,50 x 2,50 x 1,20 mts."],
        tags: [],
        picture: "assets/pools/piscina_arco_romano_pequena_blue.webp",
        pictures: ["assets/pools/piscina_arco_romano_pequena_blue.webp", "assets/pools/piscina_arco_romano_pequena_white.webp", "assets/pools/piscina_arco_romano_pequena_green.webp"]
    },
    {
        id: "FIBER0007",
        name: "Playa húmeda",
        description: "Es el espacio ideal, que cada familia necesita. Ya sea para poder tomar sol o bien disfrutar con los más pequeños.",
        category: "Piscinas de fibra",
        sizes: ["3,60 x 2,50 x 0,20 mts."],
        tags: [],
        picture: "assets/pools/playa_humeda_blue.webp",
        pictures: ["assets/pools/playa_humeda_blue.webp", "assets/pools/playa_humeda_white.webp", "assets/pools/playa_humeda_green.webp"]
    },
    {
        id: "FIBER0008",
        name: "Minipiscina con hidromasaje",
        description: "Las piscinas mini tienen muchas ventajas. En primer lugar, y seguramente la más obvia, requieren poco espacio. Podemos instalar una pequeña piscina en una terraza, un pequeño patio o jardín. También se puede anexar a la piscina que ya tenemos y convertirlo en un hermoso jacuzzi.",
        category: "Piscinas de fibra",
        sizes: ["2,20 x 2,20 x 1,00 mts."],
        tags: [],
        picture: "assets/pools/hidromasaje_white.webp",
        pictures: ["assets/pools/hidromasaje_blue.webp", "assets/pools/hidromasaje_white.webp", "assets/pools/hidromasaje_green.webp"]
    }
]

const complements = [
    {
        id: 'THERM0001',
        name: 'Bordes atérmicos',
        description: 'Un borde atérmico no sólo embellecerá su piscina, también es una pieza funcional fundamental para asegurar el máximo disfrute de su tiempo libre. La propiedad atérmica hace referencia a que su piso no va a calentarse de forma excesiva por el sol, además los mismos poseen una textura que reduce la posibilidad de deslizarse, evitando así accidentes, por eso le brindamos la máxima seguridad y confort que usted requiere mediante la instalación de bordes atérmicos.',
        category: 'Complementos',
        tags: [],
        picture: "assets/complements/borde_atermico.webp"
    },
    {
        id: 'ILLUM0001',
        name: 'Luces LED',
        description: 'Una piscina iluminada resalta la estética de su jardín, en Dent Piletas ofrecemos la última tecnología RGBW, obteniendo infinitas combinaciones de colores y mayor potencia lumínica.',
        category: 'Complementos',
        tags: [],
        picture: "assets/complements/luces_led.webp"
    },
    {
        id: 'ACCES0001',
        name: 'Cascadas',
        description: 'Accesorio ideal para aportar elegancia, belleza y hacer mucho más atractiva tu zona de baño. Sumando diversión y entretenimiento.',
        category: 'Complementos',
        tags: [],
        picture: "assets/complements/cascada.webp"
    }
]

// To be replaced by a real Database when products list grow
export const getPools = async () => {
    return pools
}

export const getComplements = async () => {
    return complements
}
